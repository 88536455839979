<div #snowContainer class="snow-container" *ngIf="enableSnow">
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
    <div class="snow">❅</div>
</div>