import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UIConfig } from '../_cryptobrew/_models/UIConfig';
import { ApiService } from '../_cryptobrew/_services/api.service';

@Component({
  selector: 'app-snow',
  templateUrl: './snow.component.html',
  styleUrls: ['./snow.component.scss']
})
export class SnowComponent implements OnInit {

  uiConfig?: UIConfig;
  enableSnow: boolean = false;

  constructor(private apiService: ApiService, private _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.toggleSnow();
  }

  toggleSnow() {
    this.apiService.get_ui_config().then((res) => {
      if (res) {
        this.uiConfig = res;
        this.enableSnow = this.uiConfig.config.enable_snow;
        this._cd.detectChanges();
      }
    });
  }


}
