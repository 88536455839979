import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoadingService } from "../_services/loading.service";
import { AlertService } from "../_services/alert.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private _loading: LoadingService, public alertService: AlertService) { }

    errorCodeMessageMap = new Map<number, string>([
        [1001, "Failed due to some internal error, please try in sometime."],
        [1002, "Please check the shared details and try again."]
    ]);

    defaultErrorMessage: string = "Failed due to some internal error, please try in sometime.";

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // TODO: add auth token and other default params here.

        this._loading.show();

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this._loading.hide();

                    // If business/handled exception 
                    if (event.body['error']) {
                        let err = event.body['error'];

                        // Only for registration flow
                        if (request.url.includes('/user') && event.body['error'].status == 404) {
                            return event;
                        }

                        if (request.url.includes('/external/referral') && event.body['error'].status == 404) {
                            return event;
                        }

                        throw new HttpErrorResponse({ error: err, status: err['status'] });
                    }

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this._loading.hide();
                let data: { [key: string]: any } = {};

                let errorMessage = this.getErrorMessage(error.status);

                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : errorMessage,
                    status: error.status
                };

                this.alertService.errorToast("Error: " + data['status'], data['reason']);
                return throwError(error);
            }));
    }

    getErrorMessage(errorCode: number) {

        if (this.errorCodeMessageMap.get(errorCode)) {
            return this.errorCodeMessageMap.get(errorCode)
        } else {
            return this.defaultErrorMessage;
        }

    }

}