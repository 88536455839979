import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgwWowModule } from 'ngx-wow';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { DecimalPipe } from '@angular/common';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertService } from './_cryptobrew/_services/alert.service';
import { LoadingService } from './_cryptobrew/_services/loading.service';
import { HttpConfigInterceptor } from './_cryptobrew/_interceptor/httpconfig.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { SnowComponent } from './snow-component/snow.component';

const firebaseConfig = {
  apiKey: "AIzaSyDhL0FCRQEBSJ6FTxKeeQwTlNkPLjO3r08",
  authDomain: "cryptobrew-5830f.firebaseapp.com",
  projectId: "cryptobrew-5830f",
  storageBucket: "cryptobrew-5830f.appspot.com",
  messagingSenderId: "492506637368",
  appId: "1:492506637368:web:59eec3133ec8750e95f2cd",
  measurementId: "G-RPRWDHMEDJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    SnowComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    HttpClientModule,
    NgwWowModule,
    RecaptchaV3Module
  ],
  providers: [DecimalPipe, AlertService,
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcjKrMdAAAAAMvhMgmPlyFWQyW_LVbGgKLqa4iz" }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
