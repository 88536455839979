import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
// import firebase from 'firebase/compat/app';
// import { AuthService } from 'src/app/modules/auth';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UIConfig } from '../_models/UIConfig';
const CONFIG_SERVICE = `${environment.configUrl}`;
const NOTIFY_SERVICE = `${environment.notifyUrl}`;

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    // private authService: AuthService,
    private router: Router,
    private http: HttpClient) { }


  get_ui_config() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.get<UIConfig>(CONFIG_SERVICE + '/external/ui/config/cryptobrew_landing_page', httpOptions).toPromise();
  }

  async create_support_ticket(ticketInfo: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(NOTIFY_SERVICE + '/external/support/ticket', ticketInfo, httpOptions).toPromise();
  }

  async join_waitlist(email: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(NOTIFY_SERVICE + '/external/stratx/join', { "email": email }, httpOptions).toPromise();
  }

  async validate_captcha(re_token: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.get(NOTIFY_SERVICE + '/external/google/recaptcha/'+re_token, httpOptions).toPromise();
  }

  // getToken() {
  //   let currentUser = firebase.auth().currentUser;
  //   if (currentUser)
  //     return currentUser.getIdToken();
  //   return Promise.reject();
  // }

  // async getMembership(membershipId: string) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': await this.getToken(),
  //       'Content-Type': 'application/json'
  //     })
  //   }
  //   return this.http.get(CORE_SERVICE + '/membership/' + membershipId, httpOptions).toPromise();
  // }

  // async createPaymentIntent(amount: string, user_id: string, product_id: string) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': await this.getToken(),
  //       'Content-Type': 'application/json'
  //     })
  //   }

  //   return this.http.post<PaymentIntent>(
  //     `https://backend.payment.powerx.cryptobrew.tech/pay/stripe/create-payment-intent`,
  //     JSON.stringify({
  //       "amount": amount,
  //       "user_id": user_id,
  //       "product_id": product_id
  //     }),
  //     httpOptions
  //   ).toPromise();
  // }

  // async upload_media(formData) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': await this.getToken()
  //     })
  //   }
  //   return this.http.put(
  //     NOTIFY_SERVICE + `/s3/object/upload`,
  //     formData,
  //     httpOptions).toPromise();
  // }

  // async get_media(mediaKey) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': await this.getToken(),
  //       'Content-Type': 'application/json'
  //     })
  //   }
  //   return this.http.get(
  //     NOTIFY_SERVICE + `/s3/object/` + mediaKey,
  //     httpOptions).toPromise();
  // }

}
