import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(private toastr: ToastrService) { }


  successToast(title: string, message: string) {
    this.toastr.success(message, title, {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }

  errorToast(title: string, message: string) {
    this.toastr.error(message, title, {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }


  // Simple Alert
  basicAlert(message: string) {
    return swal.fire(message);
  }

  showError(title: string, message: string) {
    return swal.fire({ title: title, text: message, timer: 3000, icon: "warning", showConfirmButton: false });
  }

  autoClose(title: string, message: string, time: number) {
    // 2000 is 2 sec
    return swal.fire({ title: title, text: message, timer: time, showConfirmButton: false });
  }

  // Success Type Alert
  typeSuccess(title: string, message: string) {
    return swal.fire(title, message, "success");
  }

  // Info Type Alert
  typeInfo(title: string, message: string) {
    return swal.fire(title, message, "info");
  }

  // Warning Type Alert
  typeWarning(title: string | undefined, message: string | undefined) {
    return swal.fire(title, message, "warning");
  }

  // Custom Icon
  customIcon(title: any, message: any) {
    return swal.fire({ title: title, text: message, imageUrl: "./assets/img/portrait/avatars/avatar-08.png" });
  }

  confirmCancelButton(tittle: any, message: any, type: any) {
    // type = info success warning
    return swal.fire({
      title: tittle,
      text: message,
      icon: type,
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn btn-success btn-raised mr-5',
        cancelButton: 'btn btn-danger btn-raised'
      },
      buttonsStyling: false
    });
  }



}
