import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    loadingMap: Map<string, boolean> = new Map<string, boolean>();

    constructor(private spinner: NgxSpinnerService) {
    }

    show() {
        this.spinner.show();
    }

    hide() {
        this.spinner.hide();
    }

}